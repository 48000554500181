import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { User } from '../users/user.model';
import { CigpStaff } from '../cigp-staff/cigp-staff.model';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ToastComponent } from '../shared/toast.component';
import { Account } from './account.model';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { MappingsService } from '../mappings/mappings.service';
import { UsersService } from '../users/users.service';
import { CigpStaffService } from '../cigp-staff/cigp-staff.service';
import { CigpStaffAvatarCardComponent } from '../cigp-staff/cigp-staff-avatar-card.component';
import { USER_TYPE_CLIENT, USER_TYPE_STAFF } from '../shared/constants';
import { combineLatest, tap } from 'rxjs';
import { AccountMappingsResponse } from '../mappings/mapping.model';

interface EditAccountMappingsDialogFormConfig {
  clients: FormControl<User[] | null>;
  staffs: FormControl<CigpStaff[] | null>;
}

@Component({
  selector: 'eaa-edit-account-mappings-dialog',
  standalone: true,
  imports: [
    CigpStaffAvatarCardComponent,
    DialogModule,
    MatProgressSpinnerModule,
    MultiSelectModule,
    ReactiveFormsModule,
    ToastComponent
  ],
  template: `
    <ejs-dialog #dgMappings [showCloseIcon]="true" [isModal]="true" [visible]="false" width="800px">
      <ng-template #content>
        <form [formGroup]="form">
          <!-- Clients -->
          <ejs-multiselect
            [dataSource]="clients"
            [allowFiltering]="true"
            [ignoreAccent]="true"
            [fields]="{ text: 'displayName', value: 'email' }"
            placeholder="Select client(s)"
            formControlName="clients"
            [allowObjectBinding]="true"
            (filtering)="onFilteringClients($event)"
            floatLabelType="Auto"
          >
            <ng-template #itemTemplate="" let-data="">
              <div class="d-flex justify-content-between">
                <div>{{ data.displayName }}</div>
                <div class="d-flex flex-column align-items-end">
                  <small>{{ data.email }}</small>
                </div>
              </div>
            </ng-template>
          </ejs-multiselect>

          <!-- Staffs -->
          <ejs-multiselect
            [dataSource]="staffs"
            [allowFiltering]="true"
            [ignoreAccent]="true"
            [fields]="{ text: 'displayName', value: 'email' }"
            placeholder="Select staff(s)"
            formControlName="staffs"
            floatLabelType="Auto"
            [allowObjectBinding]="true"
            (filtering)="onFilteringStaffs($event)"
          >
            <ng-template #itemTemplate="" let-data="">
              <div class="p-1">
                <eaa-cigp-staff-avatar-card [user]="data" [showName]="true"></eaa-cigp-staff-avatar-card>
              </div>
            </ng-template>
            <ng-template #valueTemplate="" let-data="">
              <eaa-cigp-staff-avatar-card [user]="data" size="18px" [showInitials]="true"></eaa-cigp-staff-avatar-card>
            </ng-template>
          </ejs-multiselect>
        </form>
      </ng-template>
      <ng-template #header>Mappings of {{ account?.shortName }} ({{ account?.name }}) </ng-template>
      <ng-template #footerTemplate>
        <div class="btn-group">
          <button class="btn btn-outline-secondary" (click)="close()">CANCEL</button>
          <button class="btn btn-primary" (click)="saveMappingsChanges()">SAVE</button>
        </div>
      </ng-template>
    </ejs-dialog>

    <eaa-toast #toastEditAccountMappingsAction [showCloseButton]="false">
      <div id="title">PROCESSING</div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ processingMessage }}
        </div>
      </div>
    </eaa-toast>
  `
})
export class EditAccountMappingsDialogComponent implements OnChanges, OnInit {
  public clients: User[] | null = null;
  public staffs: CigpStaff[] | null = null;
  public form: FormGroup = new FormGroup({});
  public processingMessage = '';

  @Input()
  public accountId: number | null | undefined = null;

  @Input()
  public account: Account | null = null;

  @ViewChild('dgMappings')
  public dialog: DialogComponent | null = null;

  @ViewChild('toastEditAccountMappingsAction')
  public toastEditAccountMappingsAction?: ToastComponent;

  constructor(
    private mappingsService: MappingsService,
    private usersService: UsersService,
    private cigpStaffService: CigpStaffService,
    private fb: FormBuilder
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['accountId']?.currentValue !== changes['accountId']?.previousValue) {
      this.form.disable();
      this.form.reset();

      if (this.accountId) {
        combineLatest([
          this.mappingsService.getAccountsMappedUserEmails$(USER_TYPE_CLIENT, this.accountId),
          this.mappingsService.getAccountsMappedUserEmails$(USER_TYPE_STAFF, this.accountId)
        ]).subscribe(([clientsResponse, staffsResponse]) => {
          this.form
            .get('clients')
            ?.setValue(
              clientsResponse && clientsResponse.length > 0
                ? this.clients?.filter((c) => clientsResponse[0].mappedUserEmails?.includes(c.email))
                : []
            );
          this.form
            .get('staffs')
            ?.setValue(
              staffsResponse && staffsResponse.length > 0
                ? this.staffs?.filter((s) => staffsResponse[0].mappedUserEmails?.includes(s.email))
                : []
            );
          this.form.enable();
        });
      }
    }
  }

  public ngOnInit(): void {
    this.usersService.getUsersFromCache$().subscribe((users) => (this.clients = users));
    this.cigpStaffService.getStaffsFromCache$().subscribe((staffs) => (this.staffs = staffs));

    this.form = this.fb.group<EditAccountMappingsDialogFormConfig>({
      clients: this.fb.control<User[]>([]),
      staffs: this.fb.control<CigpStaff[]>([])
    });
  }

  public show(): void {
    this.dialog?.show();
  }

  public close(): void {
    this.dialog?.hide();
  }

  public saveMappingsChanges() {
    if (!this.accountId || !this.account || this.form.invalid) {
      return;
    }

    this.form.disable();
    this.toastEditAccountMappingsAction?.show();

    const selectedClients = (this.form.get('clients')?.value as User[] | null) || [];
    const selectedStaffs = (this.form.get('staffs')?.value as CigpStaff[] | null) || [];

    this.processingMessage = `Saving ${selectedClients.length} client mappings`;

    const upsertClients$ = this.mappingsService
      .upsertAccountMappings$({
        account: {
          id: this.account.id,
          name: this.account.name
        },
        userEmails: selectedClients.map((m) => m.email),
        userType: USER_TYPE_CLIENT
      })
      .pipe(tap(() => (this.processingMessage = `Saving ${selectedStaffs.length} staff mappings`)));

    const upsertStaffs$ = this.mappingsService.upsertAccountMappings$({
      account: {
        id: this.account.id,
        name: this.account.name
      },
      userEmails: selectedStaffs.map((m) => m.email),
      userType: USER_TYPE_STAFF
    });

    combineLatest([upsertClients$, upsertStaffs$]).subscribe({
      next: ([clientsResponse, staffsResponse]) => this.handleAfterExecute(true, clientsResponse, staffsResponse),
      error: () => this.handleAfterExecute(false)
    });
  }

  public onFilteringClients(e: any): void {
    e.preventDefaultAction = true;

    const predicate = new Predicate('displayName', 'contains', e.text, true, true)
      .or('email', 'contains', e.text, true, true)
      .or('clientCode', 'contains', e.text, true, true);

    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;

    e.updateData(this.clients, query);
  }

  public onFilteringStaffs(e: any): void {
    e.preventDefaultAction = true;

    const predicate = new Predicate('displayName', 'contains', e.text, true, true)
      .or('email', 'contains', e.text, true, true)
      .or('initials', 'contains', e.text, true, true);

    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;

    e.updateData(this.staffs, query);
  }

  private handleAfterExecute(
    success: boolean,
    clientsResponse?: AccountMappingsResponse,
    staffsResponse?: AccountMappingsResponse
  ): void {
    if (success && clientsResponse && staffsResponse) {
      this.processingMessage = `Mappings saved.`;

      this.form
        .get('clients')
        ?.setValue(this.clients?.filter((c) => clientsResponse.mappedUserEmails?.includes(c.email)));
      this.form.get('staffs')?.setValue(this.staffs?.filter((s) => staffsResponse.mappedUserEmails?.includes(s.email)));

      setTimeout(() => {
        this.resetToast();
      }, 1500);
    } else {
      this.resetToast();
    }

    this.form.enable();
    this.dialog?.hide();
  }

  private resetToast() {
    this.toastEditAccountMappingsAction?.hide();
    this.processingMessage = '';
  }
}
