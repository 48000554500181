import { Component, OnInit, ViewChild } from '@angular/core';
import { CigpStaffService } from '../cigp-staff/cigp-staff.service';
import { Observable } from 'rxjs';
import { CigpStaff } from '../cigp-staff/cigp-staff.model';
import { AsyncPipe } from '@angular/common';
import {
  ExcelExportService,
  FilterService,
  GridComponent,
  GridModule,
  PageService,
  SearchService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { Router } from '@angular/router';
import {
  ID_EDIT_MAPPINGS,
  ID_GO_TO_DOCUMENTS,
  ID_GO_TO_MESSAGES,
  TOOLBAR_ITEM_DOCUMENTS,
  TOOLBAR_ITEM_MAPPINGS,
  TOOLBAR_ITEM_MESSAGES,
  USER_TYPE_STAFF
} from '../shared/constants';
import { EditUserMappingsDialogComponent } from './edit-user-mappings-dialog.component';

@Component({
  selector: 'eaa-staff-list',
  standalone: true,
  imports: [AsyncPipe, EditUserMappingsDialogComponent, GridModule],
  providers: [ExcelExportService, FilterService, PageService, SearchService, ToolbarService],
  template: `
    <ejs-grid
      #grdStaffs
      id="grdStaffs"
      [dataSource]="staffs$ | async"
      [allowPaging]="true"
      [pageSettings]="{ pageSize: 50, pageSizes: ['10', '20', '50', '100', '250', 'All'] }"
      [allowFiltering]="true"
      [filterSettings]="{ enableCaseSensitivity: false, ignoreAccent: true, type: 'Excel' }"
      [allowExcelExport]="true"
      [allowTextWrap]="true"
      [toolbar]="[toolbarItemDocuments, toolbarItemMessages, toolbarItemMappings, 'ExcelExport', 'Search']"
      (toolbarClick)="toolbarClick($event)"
      (rowSelected)="onRowSelected($event)"
      (rowDeselected)="onRowSelected($event)"
    >
      <e-columns>
        <e-column field="id" headerText="#" [isPrimaryKey]="true" [visible]="false"></e-column>
        <e-column field="displayName" headerText="Name"></e-column>
        <e-column field="initials" headerText="Initials"></e-column>
        <e-column field="email" headerText="Email"></e-column>
      </e-columns>
    </ejs-grid>

    <eaa-edit-user-mappings-dialog
      #dgStaffMappings
      [userDisplayName]="selectedUser?.displayName"
      [userEmail]="selectedUser?.email"
      [userType]="userType"
    ></eaa-edit-user-mappings-dialog>
  `
})
export class StaffListComponent implements OnInit {
  public staffs$: Observable<CigpStaff[]> | null = null;
  public toolbarItemDocuments = TOOLBAR_ITEM_DOCUMENTS;
  public toolbarItemMappings = TOOLBAR_ITEM_MAPPINGS;
  public toolbarItemMessages = TOOLBAR_ITEM_MESSAGES;
  public userType = USER_TYPE_STAFF;

  @ViewChild('grdStaffs')
  public grid?: GridComponent;

  @ViewChild('dgStaffMappings')
  public editStaffMappingsDialog: EditUserMappingsDialogComponent | null = null;

  constructor(
    private cigpStaffService: CigpStaffService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.staffs$ = this.cigpStaffService.getStaffsFromCache$();
  }

  public toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'grdStaffs_excelexport') {
      this.grid?.excelExport();
    } else if (args.item.id === ID_EDIT_MAPPINGS) {
      this.editStaffMappingsDialog?.show();
    } else if (args.item.id === ID_GO_TO_DOCUMENTS) {
      this.router.navigate(['/users', 'staff', (this.grid?.getSelectedRecords()[0] as CigpStaff).id, 'documents']);
    } else if (args.item.id === ID_GO_TO_MESSAGES) {
      this.router.navigate(['/users', 'staff', (this.grid?.getSelectedRecords()[0] as CigpStaff).id, 'messages']);
    }
  }

  public onRowSelected(args: any): void {
    if (args.data) {
      this.grid?.enableToolbarItems(
        [ID_EDIT_MAPPINGS, ID_GO_TO_DOCUMENTS, ID_GO_TO_MESSAGES],
        args.name === 'rowSelected'
      );
    }
  }

  public get selectedUser(): CigpStaff | null {
    return this.grid?.getSelectedRecords()[0] as CigpStaff;
  }
}
