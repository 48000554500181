import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, OperatorFunction, switchMap, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { CigpStaff } from './cigp-staff.model';
import { sortBy } from 'lodash-es';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorageService } from 'ngx-localstorage';
import { isNotInCacheOrIsStale, showErrorDialog } from '../shared/utils';
import { STORE_STAFFS } from '../shared/constants';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

const KEY_STAFFS_SET = 'staffs_set';

function sortStaffs$(): OperatorFunction<CigpStaff[], CigpStaff[]> {
  return (source: Observable<CigpStaff[]>) => source.pipe(map((staffs: CigpStaff[]) => sortBy(staffs, 'displayName')));
}

function remapCigpStaff(staff: CigpStaff): CigpStaff {
  return {
    ...staff,
    id: `cigp-${staff.id}`,
    displayName: `${staff.displayName} (CIGP)`
  };
}

function remapCigpStaffs$(): OperatorFunction<CigpStaff[], CigpStaff[]> {
  return (source: Observable<CigpStaff[]>) =>
    source.pipe(map((staffs: CigpStaff[]) => staffs.map((s) => remapCigpStaff(s))));
}

@Injectable({
  providedIn: 'root'
})
export class CigpStaffService {
  private apiEndpoint = `${environment.apiEndpoint}/api/cigp-staff`;
  public staffsSet$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private dbService: NgxIndexedDBService,
    private storage: LocalStorageService
  ) {}

  public loadCigpStaffsFromCacheAndRefreshIfNeeded$(): Observable<CigpStaff[]> {
    const staffsSet = this.storage.get<string>(KEY_STAFFS_SET);

    const obs$ = isNotInCacheOrIsStale(staffsSet)
      ? this.loadStaffsIntoCache$()
      : this.getStaffsFromCache$().pipe(switchMap((staffs) => (staffs ? of(staffs) : this.loadStaffsIntoCache$())));

    return obs$.pipe(tap(() => this.staffsSet$.next(true)));
  }

  public getStaffsFromCache$(): Observable<CigpStaff[]> {
    return this.dbService.getAll<CigpStaff>(STORE_STAFFS).pipe(
      sortStaffs$(),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getStaffFromCache$(userId: string): Observable<CigpStaff> {
    return this.dbService.getByID<CigpStaff>(STORE_STAFFS, userId).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  private loadStaffsIntoCache$(): Observable<CigpStaff[]> {
    return this.http.get<CigpStaff[]>(this.apiEndpoint).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      }),
      tap((staffs) => this.logger.log(`Successfully loaded ${staffs.length} staffs from API`)),
      remapCigpStaffs$(),
      switchMap((staffs) => {
        return this.dbService.clear(STORE_STAFFS).pipe(
          tap(() => this.logger.log('Successfully cleared staffs DB')),
          switchMap(() =>
            this.dbService.bulkAdd<CigpStaff>(STORE_STAFFS, staffs).pipe(
              catchError((error: any) => throwError(() => new Error(error))),
              tap(() => {
                this.logger.log(`Successfully loaded ${staffs.length} staffs to DB`);
                const staffsSet = moment().toISOString();
                this.storage.set(KEY_STAFFS_SET, staffsSet);
              }),
              map(() => staffs)
            )
          )
        );
      })
    );
  }
}
